define("discourse/plugins/discourse-solved/discourse/initializers/add-topic-list-class", ["exports", "discourse/components/topic-list-item", "discourse-common/utils/decorators"], function (_exports, _topicListItem, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = {
    name: "add-topic-list-class",
    initialize() {
      var _dec, _obj;
      _topicListItem.default.reopen((_dec = (0, _decorators.default)(), (_obj = {
        unboundClassNames() {
          let classList = this._super(...arguments);
          if (this.topic.has_accepted_answer) {
            classList += " status-solved";
          }
          return classList;
        }
      }, (_applyDecoratedDescriptor(_obj, "unboundClassNames", [_dec], Object.getOwnPropertyDescriptor(_obj, "unboundClassNames"), _obj)), _obj)));
    }
  };
});